<template>
	<div>
		
		<div class="Top">
			
			<div class="OrderTypes">
				我的订单
			</div>
			
			<div class="OrderSearch">
				
				<span>
					<label>订单状态</label>
					<el-select size="mini" v-model="Search.Status" placeholder="请选择状态">
					    <el-option label="全部订单" :value="-1"></el-option>
						<el-option label="待付款" :value="50"></el-option>
						<el-option label="待发货" :value="60"></el-option>
						<el-option label="待收货" :value="70"></el-option>
						<el-option label="待评价" :value="80"></el-option>
						<el-option label="申诉" :value="90"></el-option>
						<el-option label="已完成" :value="100"></el-option>
					  </el-select>
				</span>
				
				<span>
					<label>订单编号</label>
					<el-input size="mini" style="width: 200px;" v-model="Search.Id"></el-input>
				</span>
				
				<span>
					<label><em style="color: #FFFFFF;">|</em></label>
					<el-button size="mini" icon="el-icon-search" @click="StartSearching()">搜索订单</el-button>
				</span>
				
			</div>
			
		</div>
		
		<div class="TopTitle">
			<div class="C1">
				<span class="el-dropdown-link">
				  订单信息
				</span>
			</div>
			<div class="C2">数量</div>
			<div class="C3">单价</div>
			<div class="C4">小计</div>
			
		</div>

		<div class="Table" v-for="order in OrderList" :key="order.Id">
			
			<div class="Title">
				<ul class="Info">
					订单编号{{order.Id}}
					<span style="margin-left: 10px;" v-if="3>4">{{order.StatusName}}</span>
				</ul>
				<ul class="OrderId">
					创建于 {{order.CreatedAt}}
				</ul>
			</div>
			
			<div class="Grid" v-for="detail in order.Children" :key="detail.Id">
				
				<div class="C1">
					<div class="Left">
						<img :src="detail.GoodsThumb" />
					</div>
					<div class="Right">
						{{detail.GoodsName}}
						<li>{{detail.SkuName}}</li>
					</div>
				</div>
				<div class="C2">{{detail.Num}}{{detail.Unit}}</div>
				<div class="C3">{{detail.Price}}</div>
				<div class="C4">{{detail.TotalFee}}</div>

			</div>
			
			<div class="Act">
				
				<em><i style="font-style: normal;margin-right: 20px;">订单状态:<label style="color: rgba(255,74,82,1);">{{order.StatusName}}</label></i>总额:{{order.TotalFee}}元</em>
				<span>
					
					<el-button v-if="order.Status == 60" @click="$Jump('/my/shop/order_detail/'+order.Id)" type="danger" size="mini">去发货</el-button>
					
					<el-button @click="$Jump('/my/shop/order_detail/'+order.Id)" type="primary" size="mini">查看详情</el-button>
					
				</span>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
			
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'MyOrderList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Status:-1,//订单状态
				  Id:'',//订单ID
			  },
			  Page:1,
			  PageSize:10,
			  Total:0,
			  OrderList:[],
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSearching()
	  },
	  methods:{
		  handleCurrentChange(val){
		  		this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  
		  		let data = {
		  			Service:'Order',
		  			Class: 'Order',
		  			Action: 'List',
					Status:this.Search.Status,
		  			Page:_page,
		  			PageSize:this.PageSize,
					OrderId:this.Search.Id,
					ShopId:this.$store.getters.getShopId,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					
					if(res.Data.OrderList == null){
						this.OrderList = []
						return
					}
					this.OrderList = res.Data.OrderList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  
		  		let data = {
		  			Service:'Order',
		  			Class: 'Order',
		  			Action: 'Count',
		  			Status:this.Search.Status,
		  			OrderId:this.Search.Id,
					ShopId:this.$store.getters.getShopId,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
	.Top{
		display: flex;
		margin: 20px 20px 0px 20px;
		background-color: #FFFFFF;
		align-items: center;
		padding: 25px;
		
	}
	.Top .OrderTypes{
		flex: 1;
	}
	.OrderTypes span{
		margin-right: 15px;
		cursor: pointer;
	}
	.OrderTypes span.On{
		text-decoration: underline;
	}
	.Top .OrderSearch{
		display: flex;
	}
	.Top .OrderSearch span{
		margin-left: 10px;
		display: block;
	}
	.Top .OrderSearch span label{
		display: block;
		margin-bottom: 5px;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.4);
	}
	
	.TopTitle{
		padding: 10px 25px;
		display: flex;
		color: #999999;
		font-size: 0.9rem;
		margin: 5px 20px 0px 20px;
	}
	
	.C1{
		flex: 1;
		display: flex;
		align-items: center;
	}
	.C1 .Left{
		width: 120px;
	}
	.C1 .Left img{
		width: 80px;
		height: 60px;
	}
	.C1 .Right{
		flex: 1;
	}
	.C1 .Right li{
		width: 100%;
		text-align: right;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.3);
		margin-top: 10px;
	}
	.C2,.C3,.C4,.C5,.C6{
		width: 120px;
		text-align: center;
	}
	.C7{
		width: 50px;
		text-align: center;
		margin-right: 10px;
	}
	.C7 span{
		padding: 5px 10px;
		cursor: pointer;
	}
	.C7 span:hover{
		color: rgba(228,0,0,1);
	}
	
	.Table{
		margin: 20px;
		padding: 20px;
		background-color: #FFFFFF;
	}
	.Table .Title{
		display: flex;
		margin-bottom: 15px;
		color: rgba(0,0,0,0.3);
		font-size: 0.9rem;
	}
	.Table .Title .Info{
		flex: 1;
	}
	.Table .Title .OrderId{
		width: 200px;
		text-align: right;
	}
	.Table .Grid{
		display: flex;
		padding: 10px 0px;
		border-top: 1px solid rgba(0,0,0,0.04);
		align-items: center;
	}
	
	.Table .Act{
		display: flex;
		border-top: 1px solid rgba(0,0,0,0.04);
		padding: 10px 0px;
		align-items: center;
	}
	.Table .Act em{
		flex: 1;
		color: rgba(0,0,0,0.4);
	}
</style>
